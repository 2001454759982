<div class="modal-header">
    <h4 class="modal-title">{{name}} - Associated Companies</h4>
    <button class="btn btn-secondary" (click)="activeModal.dismiss()">
        <fa-icon [icon]="faX"></fa-icon>
    </button>
</div>
<div class="modal-body">
    <table class="table">
        <thead>
            <tr>
                <td>Option</td>
                <td>Include</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let company of companies">
                <td>{{company.company_name}}</td>
                <td><input type="checkbox" [value]="company.id" [name]="company.id" [(ngModel)]="company.checked"></td>
            </tr>
        </tbody>
    </table>

    <button class="btn submit mb-4" (click)="submit()" style="float: left;">Update</button>
</div>