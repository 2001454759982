<div class="modal-header">
    <h4 class="modal-title">{{name}}</h4>
    <button class="btn btn-secondary" (click)="activeModal.dismiss()">
        <fa-icon [icon]="faX"></fa-icon>
    </button>
</div>
<div class="modal-body">
    <table class="table">
        <thead>
            <tr>
                <td>Option</td>
                <td>Include</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let service of services">
                <td>{{service.title}}</td>
                <td>
                    <input type="checkbox" [name]="service.id" [value]="service.id" [checked]="checkService(service.id)" (click)="addService(service.id)">
                </td>
            </tr>
        </tbody>
    </table>

    <button class="btn submit mb-4" style="float: left;" (click)="submit()">Submit</button>
</div>

<div *ngIf="success" class="my-alert alert alert-success" role="alert">
    {{message}}
</div>

<div *ngIf="error" class="my-alert alert alert-danger" role="alert">
    {{message}}
</div>