import { Component, Input } from "@angular/core";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "src/app/_service/data.service";

@Component({
    templateUrl: 'companies.modal.html',
    styleUrls: ['companies.modal.scss'],
    host: { 'hostID': crypto.randomUUID().toString() }
})
export class CompaniesModal {
    faX = faX
    @Input() public id: number = 0;
    @Input() public name: string = '';
    companies: any = [];

    constructor(
        public activeModal: NgbActiveModal, 
        private data: DataService
    ) {}

    ngOnInit(): void {
        this.data.post('admin-users/get-companies', {user_id: this.id})
            .then((res: any) => {
                this.companies = res.companies;

                if(res.admin_companies) {
                    res.admin_companies.map((id: any) => {
                        let index = this.companies.findIndex((el: any) => el.id == id);
                        if(index >= 0) this.companies[index].checked = true;
                    })
                }
            })
            .catch((err) => console.log(err));
    }

    submit() {
        this.data.post("admin-users/update-companies", {user_id: this.id, companies: this.companies})
        this.activeModal.dismiss();
    }
}