import { Component, Input, OnInit } from "@angular/core";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "src/app/_service/data.service";

@Component({
    templateUrl: 'client-service.modal.html',
    styleUrls: ['client-service.modal.scss']
})
export class ClientServiceModal implements OnInit {
    faX = faX;

    @Input() public id: number = 0;
    @Input() public name: string = '';

    success: boolean = false;
    error: boolean = false;
    message: string = '';

    services: any = [];
    serviceCategories:any = {}

    constructor(public activeModal: NgbActiveModal, private data: DataService) {}

    ngOnInit(): void {
        this.data.post('users/get-services', {passenger_id: this.id})
            .then((res: any) => {
                this.services = res.services;
                this.serviceCategories = res.serviceCategories;
                res.user_services.map((element: any) => {
                    let index = this.services.findIndex((el: any) => el.id == element.service_id)
                    this.services[index].category = element.category;
                    this.services[index].start_date = element.start_date;
                    this.services[index].end_date = element.end_date;
                    this.services[index].cost = element.cost;
                    this.services[index].unit = element.unit;
                    this.services[index].checked = true;
                    if(!this.serviceCategories[element.service_id]) this.serviceCategories[element.service_id] = [];
                });
            })
            .catch((err) => console.log(err));
    }

    
    submitServices() {
        let includedServices: any = [];
        this.services.map((element: any) => {
            if(element.checked) {
                // Check if the property has been set
                if(!element.hasOwnProperty('cost')) element.cost = '0.00';
                if(!element.hasOwnProperty('unit')) element.unit = '';

                includedServices.push(element);
            }
        })
        
        this.data.post('users/update-services', {
            passenger_id: this.id,
            services: includedServices
        })
            .then((res: any) => {
                if (res.success) {
                    this.message = 'Record updated'
                    this.success = true
                    setTimeout(() => {
                        this.success = false
                        this.message = ''
                    }, 3000)
                }
                else {
                    this.message = 'Error: Record was not updated'
                    this.error = true
                    setTimeout(() => {
                        this.error = false
                        this.message = ''
                    }, 3000)
                }
            })
            .catch((err: any) => console.log(err));
    }
}