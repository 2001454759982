import { Component, Input, OnInit } from "@angular/core";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "src/app/_service/data.service";
import { AuditLogEntry } from '../../_core/types';

@Component({
    templateUrl: 'audit-details.modal.html',
    styleUrls: ['audit-details.modal.scss']
})
export class AuditDetailsModal implements OnInit {
    faX = faX;

    @Input() public id: number = 0;

    searching: boolean = true;
    log: AuditLogEntry|undefined;
    log_creator: any;


    constructor(
      public activeModal: NgbActiveModal, 
      private data: DataService
    ) {}

    ngOnInit(): void {
      this.data.post('audit-trail/get-details', {log_id: this.id})
      .then((res: any) => {
        this.searching = false;
        
        this.log = res.log
        this.log_creator = res.log_creator;
      })
    }

  }