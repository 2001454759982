import { Component } from '@angular/core';
import { UserService } from './_service/user.service';
import { DataService } from './_service/data.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    
    constructor(
        public user: UserService, 
        public data: DataService
    ) {
        this.data.post('login/check-auth', {
            email: this.user.email,
            token: this.user.token
        }).then((res:any) => { if (!res.login) this.user.signOut() })

        if(!this.user.id) this.user.signOut();
    }
}
