import { Component, Input, OnInit } from "@angular/core";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "src/app/_service/data.service";
import { UserService } from "src/app/_service/user.service";

@Component({
    templateUrl: 'permissions.modal.html',
    styleUrls: ['permissions.modal.scss'],
    host: { 'hostID': crypto.randomUUID().toString() }
})
export class PermissionsModal implements OnInit {
    faX = faX;
    @Input() public id: number = 0;
    @Input() public name: string = '';
    permissions: any = [];

    constructor(
        public activeModal: NgbActiveModal, 
        private data: DataService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.data.post('admin-users/get-permissions', {user_id: this.id})
            .then((res: any) => {
                this.permissions = res.permissions;

                if(res.admin_permissions) {
                    res.admin_permissions.map((id: any) => {
                        let index = this.permissions.findIndex((el: any) => el.id == id);
                        if(index >= 0) this.permissions[index].checked = true;
                    })
                }
            })
            .catch((err) => console.log(err));
    }

    submit() {
        this.data.post("admin-users/update-permissions", {user_id: this.id, permissions: this.permissions})
        
        if(this.id == this.userService.id) {
            let perm_list = this.permissions.map((p:any)=>{ if(p.checked) return p.tablename })
            this.userService.permissions = perm_list;
        }
        this.activeModal.dismiss();
    }
}