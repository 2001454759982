import { Component, Input, OnInit } from "@angular/core";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "src/app/_service/data.service";

@Component({
    templateUrl: 'provider-service.modal.html',
    styleUrls: ['provider-service.modal.scss']
})
export class ProviderServiceModal implements OnInit {
    faX = faX
    @Input() public id: number = 0;
    @Input() public name: string = '';
    services: any = [];
    includedServices: any = [];

    success: boolean = false;
    error: boolean = false;
    message: string = '';

    constructor(public activeModal: NgbActiveModal, private data: DataService) {}

    ngOnInit(): void {
        this.data.post('providers/get-services', {id: this.id})
        .then((res: any) => {
            this.services = res.services;
            this.includedServices = res.provider_services.map((element: any) => element.service_id);
        })
        .catch((err) => console.log(err));

    }

    checkService(id: number) {
        let found = this.includedServices.find((element: any) => element == id);
        return found ? true : false;
    }

    addService(id: number) {
        let index = this.includedServices.findIndex((element: any) => element == id);
        if(index === -1) {
            this.includedServices.push(id);
        }
        else {
            this.includedServices.splice(index, 1);
        }
    }

    submit() {
        this.data.post('providers/update-services', {
            id: this.id,
            services: this.includedServices
        })
        .then((res: any) => {
            if(!res.error) {
              this.message = 'Record updated'
              this.success = true
              setTimeout(() => {
                this.success = false
                this.message = ''
              }, 3000)
            }
            else {
              this.message = 'Error: Record was not updated'
              this.error = true
              setTimeout(() => {
                this.error = false
                this.message = ''
              }, 3000)
            }
          })
          .catch((err) => console.log(err));
    }
}