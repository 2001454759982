<div class="modal-header">
    <h4 class="modal-title">Update Field</h4>
    <button class="btn btn-secondary" (click)="activeModal.dismiss()">
        <fa-icon [icon]="faX"></fa-icon>
    </button>
</div>
<div class="modal-body">
    <label class="label" style="font-style:italic;">one value per line</label>
    <input *ngFor="let option of question.options; let i = index; trackBy: trackByFn" class="form-control mt-1" [name]="option" [(ngModel)]="question.options[i]">
    <input class="form-control mt-1 mb-4" name="newOption" [(ngModel)]="newOption">
    <button class="btn btn-secondary" (click)="updateField()">Update</button>
</div>