<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap">
  <span class="navbar-brand sidebar">
    DeKalb Human Services
  </span>

  <img src="assets/portal-logo.png" class="logo ms-auto me-auto">
  <span class="" id="header-user">Welcome, {{user.username}}!</span>

  <ul class="navbar-nav px-3">
    <li class="nav-item text-nowrap">
      <span class="signout" (click)="user.signOut()">Sign out <fa-icon [icon]="faRightFromBracket"></fa-icon></span>
    </li>
  </ul>
</nav>