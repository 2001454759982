<section class="row" *ngIf="fields[type]">
  <h4>{{header}}</h4>
  <p class="italic">{{subheader}}</p>
  <div class="col-3">
    <label>Level of Impairment ({{section_type}}):</label>
    <p class="answer" *ngIf="action == 'view'">{{fields[type].loi[section_variable] ? fields[type].loi[section_variable] : '0'}}</p>
    <div *ngIf="action == 'new'">
      <label class="radio-label"><input type="radio" value="0" [(ngModel)]="fields[type].loi[section_variable]" [name]="type+'_loi_'+section_type"> 0</label>
      <label class="radio-label"><input type="radio" value="1" [(ngModel)]="fields[type].loi[section_variable]" [name]="type+'_loi_'+section_type"> 1</label>
      <label class="radio-label"><input type="radio" value="2" [(ngModel)]="fields[type].loi[section_variable]" [name]="type+'_loi_'+section_type"> 2</label>
      <label class="radio-label"><input type="radio" value="3" [(ngModel)]="fields[type].loi[section_variable]" [name]="type+'_loi_'+section_type"> 3</label>
    </div>
  </div>
  <div class="col-3">
    <label>Unmet Need ({{section_type}}):</label>
    <p class="answer" *ngIf="action == 'view'">{{fields[type].un[section_variable] ? fields[type].un[section_variable] : '0'}}</p>
    <div *ngIf="action == 'new'">
      <label class="radio-label"><input type="radio" value="0" [(ngModel)]="fields[type].un[section_variable]" [name]="type+'_un_'+section_type"> 0</label>
      <label class="radio-label"><input type="radio" value="1" [(ngModel)]="fields[type].un[section_variable]" [name]="type+'_un_'+section_type"> 1</label>
      <label class="radio-label"><input type="radio" value="2" [(ngModel)]="fields[type].un[section_variable]" [name]="type+'_un_'+section_type"> 2</label>
      <label class="radio-label"><input type="radio" value="3" [(ngModel)]="fields[type].un[section_variable]" [name]="type+'_un_'+section_type"> 3</label>
    </div>
  </div>
  <div class="col-6">
    <label>Comments ({{section_type}}):</label>
    <p class="answer" *ngIf="action == 'view'">{{fields[type].comments[section_variable]}}</p>
    <div *ngIf="action == 'new'">
      <textarea [name]="type+'_un_'+section_type" rows="5" [(ngModel)]="fields[type].comments[section_variable]"></textarea>
    </div>
  </div>
</section>