<app-header *ngIf="user.token"></app-header>
<div class="my-container">
    <div *ngIf="user.token" class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="content">
        <router-outlet></router-outlet>

        <div 
        *ngIf="data.message.show" 
        [ngClass]="{
            'my-alert':true, 
            'alert': true, 
            'alert-danger': data.message.type=='error', 
            'alert-success': data.message.type=='success'
        }" role="alert">
        {{data.message.content}}
        </div>
    </div>
</div>