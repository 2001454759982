<ul class="list">
    <h3 class="text-center" *ngIf="!environment.production">Staging Environment</h3>
    <li>
        <a routerLink="/appointments" routerLinkActive="active-link">
            <span class="icon"><fa-icon [icon]="faCalendarCheck"></fa-icon></span>
            Appointments
        </a>
    </li>
    <li>
        <a routerLink="/companies" routerLinkActive="active-link">
            <span class="icon"><fa-icon [icon]="faBuilding"></fa-icon></span>
            Companies
        </a>
    </li>
    <li>
        <a routerLink="/users" routerLinkActive="active-link">
            <span class="icon"><fa-icon [icon]="faUsers"></fa-icon></span>
            Users
        </a>
    </li>
    <li>
        <a routerLink="/providers" routerLinkActive="active-link">
            <span class="icon"><fa-icon [icon]="faAddressCard"></fa-icon></span>
            Providers
        </a>
    </li>
    <li>
        <a routerLink="/senior-centers" routerLinkActive="active-link">
            <span class="icon"><fa-icon [icon]="faHotel"></fa-icon></span>
            Senior Centers
        </a>
    </li>
    <li>
        <a routerLink="/services" routerLinkActive="active-link">
            <span class="icon"><fa-icon [icon]="faHandHoldingHeart"></fa-icon></span>
            Services
        </a>
        </li>
    <li>
        <a routerLink="/admin-users" routerLinkActive="active-link">
            <span class="icon"><fa-icon [icon]="faUsers"></fa-icon></span>
            Admin Users
        </a>
    </li>
    <li>
        <a routerLink="/activity-report" routerLinkActive="active-link">
            <span class="icon"><fa-icon [icon]="faChartBar"></fa-icon></span>
            Activity Report
        </a>
    </li>
    <li>
        <a routerLink="/reports-builder" routerLinkActive="active-link">
            <span class="icon"><fa-icon [icon]="faChartBar"></fa-icon></span>
            Reports Builder
        </a>
    </li>
    <li *ngIf="permissions.includes('kpi_data')">
        <a routerLink="/kpi-data-entry" routerLinkActive="active-link">
            <span class="icon"><fa-icon [icon]="faTable"></fa-icon></span>
            KPI Data Entry
        </a>
    </li>
    <li *ngIf="permissions.includes('audit_trail')">
        <a routerLink="/audit-trail" routerLinkActive="active-link">
            <span class="icon"><fa-icon [icon]="faRoad"></fa-icon></span>
            Audit Trail
        </a>
    </li>
</ul>