import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AES, enc } from 'crypto-js';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DataService {
  
  message = {
    type: '',
    content: '',
    show: false
  }
  
  private api = environment.api_url;
  private encryptSecretKey: string = environment.encryptSecretKey;

  constructor(private http: HttpClient, private userService: UserService) { }


  showMessage(content: string, type: "success"|"error" = "success") {
    this.message.content = content;
    this.message.type = type;
    this.message.show = true;
    
    setTimeout(() => {
      this.message.show = false;
      this.message.content = '';
      this.message.type = '';
    }, 3000);
  }

  getHeaders() {
    return ({
      headers: new HttpHeaders({
        'X-Auth-Token': this.userService.token ? this.userService.token : "",
        'Content-Type': 'application/json'
      })
    })
  }

  encryptData(data:any) {
    try {
      return AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } 
    catch (e) {
      console.log(e);
      return false;
    }
  }

  decryptData(data:any) {
    try {
      const bytes = AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) return JSON.parse(bytes.toString(enc.Utf8));
      return data;
    } 
    catch (e) {
      console.log(e);
      return false
    }
  }

  async get(endpoint: string) {
    let httpOpts = await this.getHeaders();
    return lastValueFrom(this.http.get<any>(this.api + endpoint, httpOpts))
  }

  async post(endpoint: string, data: object) {
    let httpOpts = await this.getHeaders();
    return lastValueFrom(this.http.post(this.api + endpoint, data, httpOpts))
  }
}
