import { Component } from '@angular/core';
import { faCalendarCheck, faBuilding, faUsers, faAddressCard, faHotel, faHandHoldingHeart, faChartBar, faTable, faRoad } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/_service/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  faCalendarCheck = faCalendarCheck
  faBuilding = faBuilding
  faUsers = faUsers
  faAddressCard = faAddressCard
  faHotel = faHotel
  faHandHoldingHeart = faHandHoldingHeart
  faChartBar = faChartBar
  faTable = faTable;
  faRoad = faRoad;

  environment = environment;

  permissions: Array<string> = this.userService.permissions

  constructor(
    public userService: UserService,
  ) {
    setInterval(()=>{
        this.permissions = this.userService.permissions
    }, 1000)
  }

}
