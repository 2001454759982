import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from './_components/components.module';
import { ModalsModule } from './_modals/modals.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		ComponentsModule,
		FormsModule,
		AppRoutingModule,
		HttpClientModule,
		NgbModule,
		ModalsModule,
		MatDialogModule,
		FontAwesomeModule,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
