import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "src/app/_service/data.service";

@Component({
    templateUrl: 'assessments.modal.html',
    styleUrls: ['assessments.modal.scss']
})
export class AssessmentsModal implements OnInit {
    faX = faX;

    @Input() public id: number = 0;
    @Input() public name: string = '';

    success: boolean = false;
    error: boolean = false;
    message: string = '';

    assessments:any = [];

    constructor(
      public activeModal: NgbActiveModal, 
      private router: Router,
      private data: DataService
    ) {}

    ngOnInit(): void {
      this.data.post('assessments/load', {passenger_id: this.id})
      .then((res: any) => {
        this.assessments = res.forms
      })
      .catch((err) => console.log(err));
    }

    navigate(form_type:string, id:number) {
      this.activeModal.close();
      console.log(`/assessment-form/${form_type}/new/${id}`)
      this.router.navigate([`/assessment-form/${form_type}/new/${id}`]);
    }

  }