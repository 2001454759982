import { Component, Input } from '@angular/core';

@Component({
  selector: 'donr-section',
  templateUrl: './donr-section.component.html',
  styleUrls: ['./donr-section.component.scss']
})

export class DonrSectionComponent {
  @Input() header: string = ""
  @Input() subheader: string = ""
  @Input() section_type: string = ""
  @Input() action: string = ""
  @Input() fields: any;
  @Input() type: string = "";
  @Input() section_variable: string = ""

  constructor() {  

  }


}
