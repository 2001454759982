<div class="modal-header">
    <h4 class="modal-title">{{name}}</h4>
    <button class="btn btn-secondary" (click)="activeModal.dismiss()">
        <fa-icon [icon]="faX"></fa-icon>
    </button>
</div>
<div class="modal-body">
    <table class="table">
        <thead>
            <tr>
                <td>Option</td>
                <td>Include</td>
                <td>Category</td>
                <td>Start Date</td>
                <td>End Date</td>
                <td>Cost</td>
                <td>Unit</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let service of services">
                <td class="title">{{service.title}}</td>
                <td><input type="checkbox" [name]="'include_'+service.id" [(ngModel)]="service.checked"></td>
                <td>
                    <select class="form-control" [name]="'category_'+service.id" multiple [(ngModel)]="service.category">
                        <option value="">(select)</option>
                        <option *ngFor="let cat of serviceCategories[service.id]" [value]="cat.id">{{cat.name}}</option>
                    </select>
                </td>
                <td>
                    <input type="date" [name]="'start_date_'+service.id" [(ngModel)]="service.start_date">
                </td>
                <td>
                    <input type="date" [name]="'end_date_'+service.id" [(ngModel)]="service.end_date">
                </td>
                <td>
                    <select class="form-control" [name]="'cost_'+service.id" [(ngModel)]="service.cost">
                        <option value="" disabled>(select)</option>
                        <option value="5">$5.00</option>
                        <option value="10">$10.00</option>
                        <option value="15">$15.00</option>
                        <option value="20">$20.00</option>
                        <option value="25">$25.00</option>
                    </select>
                    <!-- <input type="number" class="form-control cost" [name]="'cost_'+service.id" [(ngModel)]="service.cost"> -->
                </td>
                <td>
                    <select class="form-control" [name]="'unit_'+service.id" [(ngModel)]="service.unit">
                        <option value="">(select)</option>
                        <option value="hour">hour</option>
                        <option value="appointment">appointment</option>
                    </select>
                </td>
            </tr>
        </tbody>
    </table>

    <button class="btn submit mb-4" style="float:left;" (click)="submitServices()">Submit</button>
</div>

<div *ngIf="success" class="my-alert alert alert-success" role="alert">
    {{message}}
</div>

<div *ngIf="error" class="my-alert alert alert-danger" role="alert">
    {{message}}
</div>