import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    get permissions(): Array<string> {
        let perms = localStorage.getItem('permissions');
        if(perms != null) return JSON.parse(perms);
        return [];
    }
    set permissions(permissions: any) {
        localStorage.setItem('permissions', JSON.stringify(permissions))
    }

    get token(): string {
        return localStorage.getItem('X-Auth-Token')!
    }
    set token(token: string) {
        localStorage.setItem('X-Auth-Token', token)
    }

    get username(): string {
        return localStorage.getItem('username')!
    }
    set username(name: string) {
        localStorage.setItem('username', name)
    }

    get id(): number {
        let id = localStorage.getItem('id');
        if(id != null) return JSON.parse(id);
        else return 0;
    }
    set id(id: number) {
        localStorage.setItem('id', JSON.stringify(id))
    }


    get email(): string {
        return localStorage.getItem('email')!
    }
    set email(email: string) {
        localStorage.setItem('email', email)
    }

    constructor(private router: Router) { }

    signOut() {
        localStorage.removeItem('X-Auth-Token')
        localStorage.removeItem('email')
        localStorage.removeItem('username')
        this.router.navigate(['/login'])
    }
}


export interface User {
    id: number;
    first_name: string;
    last_name: string;
    status?: number;
}

export interface Department {
    id: number;
    name: string;
    owner_id: number;
    owner_name?: string;
}

export interface Form {
    id: string;
    name: string;
    submitted_by: string;
    created_at: string;
    date: string;
    due_date: string;
    sections?: Section[];
    frequency: string;
    submission_id?: string;
    partial?: boolean;
}

export interface Section {
    id: number;
    name: string;
    fields: Field[]
}
export interface Field {
    id: number;
    label: string;
    type: string;
    answer?: string;
    prefill?: any;
}