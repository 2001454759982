import { Component, Input, OnInit } from "@angular/core";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "src/app/_service/data.service";

@Component({
    templateUrl: 'update-service.modal.html',
    styleUrls: ['update-service.modal.scss']
})
export class UpdateServiceModal implements OnInit {
    faX = faX;
    @Input() public question: any;
    newOption: string = '';
    message: string = '';

    constructor(public activeModal: NgbActiveModal, private data: DataService) {
    }

    ngOnInit(): void {
        this.question.options = JSON.parse(this.question.options)
        console.log('modal', this.question)
    }

    trackByFn(index: any, item: any) {
        return index;  
    }

    updateField() {
        let OptionsArr = []
        if(this.question.options) {
            OptionsArr = this.question.options;
        }
        if(this.newOption) {
            OptionsArr.push(this.newOption);
        }

        let OptionsStr = JSON.stringify(OptionsArr);
        this.data.post('services/update-options', {
            id: this.question.id,
            options: OptionsStr
        }).then((res: any) => {
            if(!res.error) {
                if(this.newOption) {
                    this.newOption = '';
                }
                this.question.options = JSON.parse(res.options);
            }
            else {
                this.message = res.error;
                setTimeout(() => {
                this.message = '';
                }, 3000)
            }

            console.log('update', this.question.options)
        })
    }
}