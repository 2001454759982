<div class="modal-header">
  <h4 class="modal-title">{{name}}</h4>
  <button class="btn btn-secondary" (click)="activeModal.dismiss()">
      <fa-icon [icon]="faX"></fa-icon>
  </button>
</div>
<div class="modal-body">
  <h4>Create New Form</h4>
  <button (click)="navigate('don-r', id)" class="btn btn-primary">DON-R</button>
  <button (click)="navigate('nsi', id)" class="btn btn-primary">NSI</button>
  <button (click)="navigate('transportation', id)" class="btn btn-primary">Transportation</button>

  <br><br>

  <h4>Saved Forms</h4>

  <p *ngIf="assessments.length == 0" class="text-center">There are no submitted assessment forms for this user</p>
  <table class="table" *ngIf="assessments.length > 0">
      <thead>
          <tr>
              <td>Form Type</td>
              <td>Date</td>
              <td></td>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let assessment of assessments">
              <td class="form_type">{{assessment.type_display ? assessment.type_display : assessment.type}}</td>
              <td>
                <p>{{assessment.created_at | date: 'short'}}</p>
              </td>
              <td>
                <a class="btn submit" (click)="activeModal.close()" routerLink="/assessment-form/{{assessment.type}}/view/{{assessment.id}}">View</a>
              </td>
          </tr>
      </tbody>
  </table>
</div>

<div *ngIf="success" class="my-alert alert alert-success" role="alert">
  {{message}}
</div>

<div *ngIf="error" class="my-alert alert alert-danger" role="alert">
  {{message}}
</div>