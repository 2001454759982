import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { RouterModule } from "@angular/router";

import { ClientServiceModal } from "./client-service/client-service.modal";
import { CompaniesModal } from "./companies/companies.modal";
import { PermissionsModal } from "./permissions/permissions.modal";
import { ProviderServiceModal } from "./provider-service/provider-service.modal";
import { UpdateServiceModal } from "./update-service/update-service.modal";
import { AssessmentsModal } from "./assessments/assessments.modal";
import { AuditDetailsModal } from "./audit-details/audit-details.modal";

@NgModule({
    declarations: [
        ClientServiceModal,
        AssessmentsModal,
        AuditDetailsModal,
        ProviderServiceModal,
        UpdateServiceModal,
        PermissionsModal,
        CompaniesModal
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        FontAwesomeModule
    ]
})
export class ModalsModule {}