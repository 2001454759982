<div class="modal-header">
    <h4 class="modal-title">Audit Log Details</h4>
    <button class="btn btn-secondary" (click)="activeModal.dismiss()">
        <fa-icon [icon]="faX"></fa-icon>
    </button>
  </div>
  <div class="modal-body">
    
    <img class="loader" *ngIf="!log && searching" src="assets/loader.gif" alt="">
    <p *ngIf="!log && !searching">Unable to find log details</p>



    <p *ngIf="log_creator">Change made by {{log_creator.first_name}} {{log_creator.last_name}}</p>
    <p *ngIf="log">Page: {{log.page}}</p>
    <p *ngIf="log">Action Type: {{log.type_readable}}</p>

    
    <table class="table" *ngIf="log">
        <thead>
            <tr>
                <td>Key</td>
                <td *ngIf="log.original_content">Original Value</td>
                <td *ngIf="log.new_content">New Value</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of log.summary">
                <td>{{row.key}}</td>
                <td *ngIf="row.original_value" [innerHTML]="row.original_value"></td>
                <td *ngIf="row.new_value" [innerHTML]="row.new_value"></td>
            </tr>
        </tbody>
    </table>


  </div>