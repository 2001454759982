<div class="modal-header">
    <h4 class="modal-title">{{name}} - User Permissions</h4>
    <button class="btn btn-secondary" (click)="activeModal.dismiss()">
        <fa-icon [icon]="faX"></fa-icon>
    </button>
</div>
<div class="modal-body">
    <table class="table">
        <thead>
            <tr>
                <td>Option</td>
                <td>Include</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let permission of permissions">
                <td>{{permission.display}}</td>
                <td><input type="checkbox" [value]="permission.id" [name]="permission.id" [(ngModel)]="permission.checked"></td>
            </tr>
        </tbody>
    </table>

    <button class="btn submit mb-4" (click)="submit()" style="float: left;">Update</button>
</div>